.ql-toolbar.ql-snow {
    border: none;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  }
  
  .ql-container.ql-snow {
    border: none;
  }
  
  .ql-editor {
    min-height: 112px;
    font-size: 1rem;
    font-family: "Roboto", sans-serif;
  }
  
  .ql-editor p {
    margin-bottom: 10px;
  }
  
  .ql-editor strong {
    font-weight: 700;
  }
  